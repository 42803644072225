<!--
 * @Description: 
 * @Author: Ray
 * @Date: 2021-12-17 15:06:42
 * @LastEditTime: 2023-12-28 12:15:00
 * @LastEditors: Ray
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
body {
  margin: 0;
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
}
label {
  font-weight: 700;
}

html {
  height: 100%;
  box-sizing: border-box;
}

#app {
  height: 100%;
  /* overflow: scroll; */
}
p {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
} /*清除内外边距*/
.el-textarea__inner {
  font-size: 13px;
  color: #303133;
  font-weight: 400;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
}
.el-input__inner {
  font-size: 13px;
  color: #303133 !important;
  font-weight: 400;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
a:focus,
a:active {
  outline: none;
}
a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

div:focus {
  outline: none;
}

.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
}
.edui-default .edui-toolbar .edui-for-fullscreen {
  display: none !important;
}
.el-select-dropdown__list {
  overflow: scroll !important;
  overflow-y: auto !important;
}
.articleDetail-content-detail p img {
  width: 100%;
}
.el-table {
  flex: none !important;
}
.pdf-view {
  width: 100%;
}
.pdf-view .page {
  width: 100% !important;
}
.pdfViewer .page {
  width: 100% !important;
}
.pdf-view .page .canvasWrapper {
  width: 100% !important;
}
.pdf-view .page svg {
  width: 100% !important;
}
.el-message-box__message p {
  display: inline-block;
  margin: 0;
  line-height: 24px;
  /* width: 200px; */
  /* text-align: center; */
}
.el-message-box__input input.invalid,
.el-message-box__input input.invalid:focus {
  border-color: #f56c6c;
}
.el-message-box__btns button:nth-child(1) {
  background-color: #1B4298!important;
  border-color: #1B4298!important;
  color: #ffffff!important;
}
.el-message-box__btns button:nth-child(2) {
  background-color: #ffffff!important;
  border: 1px solid #DCDFE6!important;
  color: #000000!important;
}
.el-message-box__btns button:nth-child(2):hover{
  background-color: #1B4298!important;
  border-color: #1B4298!important;
  color: #ffffff!important;
}
.textarea {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
  outline: none !important;
}
.textarea:focus {
  outline: normal !important;
  border: '1px solid #1B4298';
}
</style>
