/*
 * @Description:
 * @Author: Ray
 * @Date: 2021-12-17 15:06:44
 * @LastEditTime: 2021-12-17 15:25:36
 * @LastEditors: Ray
 */
import Vue from 'vue'
import Vuex from 'vuex'
// import index from './modules/index'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    cancelTokenArr: [], // 取消请求token数组
  },
  mutations: {
    pushToken(state, payload) {
      state.cancelTokenArr.push(payload.cancelToken)
    },
    clearRequest({ cancelTokenArr }) {
      cancelTokenArr.forEach((item) => {
        item('路由跳转取消请求')
      })
      cancelTokenArr = []
    },
  },
})

export default store
