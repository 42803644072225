import axios from 'axios'
import xss from 'xss'
import store from '../vuex/store'
import ErrorCode from './errorCode.js'
var bcrypt = require('bcryptjs')

// 防止同一时间多次请求同一接口
axios.defaults.timeout = 30000
const pending = []
const CancelToken = axios.CancelToken
const removePending = (config) => {
  for (const p in pending) {
    if (pending[p].u === config.url + '&' + config.method) {
      pending[p].f()
      pending.splice(p, 1)
    }
  }
}
// 在main.js设置全局的请求次数，请求的间隙
// axios.defaults.retry = 0
// axios.defaults.retryDelay = 0
// axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
//   var config = err.config
//   if (!config || !config.retry) return Promise.reject(err)
//   config.__retryCount = config.__retryCount || 0
//   if (config.__retryCount >= config.retry) {
//     return Promise.reject(err)
//   }
//   config.__retryCount += 1
//   var backoff = new Promise(function(resolve) {
//     setTimeout(function() {
//       resolve()
//     }, config.retryDelay || 1)
//   })
//   return backoff.then(function() {
//     return axios(config)
//   })
// })
// 添加请求拦截器
axios.interceptors.request.use(
  (config) => {
    // console.log('config', config.headers.Authorization)
    if (!config.headers.Authorization) {
      removePending(config) // 在一个ajax发送前执行一下取消操作
      config.cancelToken = new CancelToken((c) => {
        pending.push({ u: config.url + '&' + config.method, f: c })
        store.commit('pushToken', { cancelToken: c })
      })
    }
    // removePending(config) // 在一个ajax发送前执行一下取消操作
    // config.cancelToken = new CancelToken((c) => {
    //   pending.push({ u: config.url + '&' + config.method, f: c })
    //   store.commit('pushToken', { cancelToken: c })
    // })
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
let tokenErrorStatus = ''
const resTokenErrorControl = (status) => {
  tokenErrorStatus = status
}
axios.interceptors.response.use(
  (response) => {
    if (response.data.data) {
      response.data.data = response.data.data
    }
    return response
  },
  (error) => {
    let status = ''
    if (error.request) {
      status = error.request
    } else if (error.response) {
      status = error.response
    }
    if (status) {
      if (status.status) {
        if (status.status == '401') {
          if (!tokenErrorStatus) {
            resTokenErrorControl(true)
            return Promise.reject(error)
          } else {
            let data = {
              status: '00000',
            }
            return Promise.resolve(data)
          }
        } else {
          return Promise.reject(error)
        }
      }
    }
  }
)

export default {
  baseUrl: process.env.VUE_APP_URL,
  signUrl: process.env.VUE_APP_URL,
  userPermission: [],
  comparePermisson(modelUrl) {
    var modeAllData = JSON.parse(localStorage.getItem('permissions'))
    var computeResult = false
    if (modeAllData.indexOf(modelUrl) != -1) {
      computeResult = true
    }
    return computeResult
  },
  //操作成功提示公用方法
  success(obj, msg, url) {
    obj.$notify({
      message: msg,
      type: 'success',
      offset: 38,
      duration: '2000',
      onClose: function() {
        if (url !== '' && url != null) {
          obj.$router.push(url)
        }
      },
    })
  },
  //操作失败提示公用方法
  error(obj, msg, url) {
    obj.$notify.error({
      message: msg,
      title: '错误',
      type: 'error',
      offset: 38,
      duration: 0,
      onClose: function() {
        if (url !== '' && url != null) {
          obj.$router.push(url)
        }
      },
    })
  },
  createOptionsSign(option, url, data) {
    if (data) {
      data.sign = null
      const signStr =
        option +
        ',' +
        this.signUrl +
        url +
        ',' +
        this.paramsSort(this.postHttpData(data.data))
      const salt = bcrypt.genSaltSync(10)
      const hash = bcrypt.hashSync(signStr, salt)
      return hash
    } else {
      const signStr = option + ',' + this.signUrl + url
      const salt = bcrypt.genSaltSync(10)
      const hash = bcrypt.hashSync(signStr, salt)
      return hash
    }
  },
  createGetSign(option, url, data) {
    if (data) {
      data.sign = null
      data.timestamp = null
      if (JSON.stringify(this.postHttpData(data)) === '{}') {
        const signStr = option + ',' + this.signUrl + url
        const salt = bcrypt.genSaltSync(10)
        const hash = bcrypt.hashSync(signStr, salt)
        return hash
      } else {
        const signStr =
          option +
          ',' +
          this.signUrl +
          url +
          ',' +
          this.paramsSort(this.postHttpData(data))
        const salt = bcrypt.genSaltSync(10)
        const hash = bcrypt.hashSync(signStr, salt)
        return hash
      }
    } else {
      const signStr = option + ',' + this.signUrl + url
      const salt = bcrypt.genSaltSync(10)
      const hash = bcrypt.hashSync(signStr, salt)
      return hash
    }
  },
  paramsSort(obj) {
    const sdic = Object.keys(obj).sort()
    let temp = ''

    for (const index in sdic) {
      if (sdic[index] !== 'timestamp') {
        if (obj[sdic[index]] !== '') {
          if (typeof obj[sdic[index]] !== 'object') {
            temp += `${sdic[index]}=${obj[sdic[index]]}&`
          } else {
            temp += `${sdic[index]}=${JSON.stringify(obj[sdic[index]])}&`
          }
        }
      }
    }

    return temp.substring(0, temp.length - 1)
  },
  httpGetWithToken(self, url, data) {
    const _this = this
    const sign = this.createGetSign('get', url, data)
    if (data) {
      data['sign'] = sign
      data['timestamp'] = parseInt(Date.now() / 1000)
    } else {
      data = {
        sign: sign,
        timestamp: parseInt(Date.now() / 1000),
      }
    }
    if (data.order) {
      data.order = encodeURIComponent(data.order)
    }
    encodeURIComponent(this.order)
    return new Promise((resolve, reject) => {
      axios
        .get(this.baseUrl + url + '?' + this.getHttpData(data), {
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
          },
        })
        .then((res) => {
          if (res.data.status == 1) {
            resolve(res.data)
          } else {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res.data.message)
          }
        })
        .catch((error) => {
          reject(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  httpDeleteWithToken(self, url, comment, data) {
    const _this = this
    const sign = this.createGetSign('delete', url, data)
    if (data) {
      data['sign'] = sign
      data['timestamp'] = parseInt(Date.now() / 1000)
    } else {
      data = {
        sign: sign,
        timestamp: parseInt(Date.now() / 1000),
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .delete(this.baseUrl + url + '?' + this.getHttpData(data), {
          data: {
            data: comment ? JSON.parse(xss(JSON.stringify(comment))) : null,
          },
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
          },
        })
        .then((res) => {
          if (res.status == 204) {
            resolve(res)
          } else if (res.data.code) {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res)
          }
        })
        .catch((error) => {
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  httpPostWithToken(self, url, data) {
    const _this = this
    const sign = this.createOptionsSign('post', url, data)
    if (data) {
      data['sign'] = sign
      data['timestamp'] = parseInt(Date.now() / 1000)
    } else {
      data = {
        sign: sign,
        timestamp: parseInt(Date.now() / 1000),
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .post(this.baseUrl + url, this.postHttpData(data), {
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
          },
        })
        .then((res) => {
          if (res.data.status == 1) {
            resolve(res.data)
          } else {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res.data.message)
          }
        })
        .catch((error) => {
          reject(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  httpFormPost(self, url, file, config) {
    const _this = this
    return new Promise((resolve, reject) => {
      axios
        .post(this.baseUrl + url, file, config)
        .then((res) => {
          if (res.data.status == 1) {
            resolve(res.data)
          } else {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res.data.message)
          }
        })
        .catch((error) => {
          reject(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  httpPutWithToken(self, url, data) {
    const _this = this
    const sign = this.createOptionsSign('put', url, data)
    if (data) {
      data['sign'] = sign
      data['timestamp'] = parseInt(Date.now() / 1000)
    } else {
      data = {
        sign: sign,
        timestamp: parseInt(Date.now() / 1000),
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .put(this.baseUrl + url, this.postHttpData(data), {
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
          },
        })
        .then((res) => {
          if (res && res.data.status == 1) {
            resolve(res.data)
          } else {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res.data.message)
          }
        })
        .catch((error) => {
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
          reject(error)
        })
    })
  },
  httpGet(self, url, data) {
    const sign = this.createGetSign('get', url, data)
    if (data) {
      data['sign'] = sign
      data['timestamp'] = parseInt(Date.now() / 1000)
    } else {
      data = {
        sign: sign,
        timestamp: parseInt(Date.now() / 1000),
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .get(this.baseUrl + url + '?' + this.getHttpData(data))
        .then((res) => {
          if (res.data.status == 1) {
            resolve(res.data)
          } else {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res.data.message)
          }
        })
        .catch((error) => {
          reject(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  postHttpData: function(data) {
    let paramsData = JSON.parse(xss(JSON.stringify(data)))
    var temp = {}
    for (var i in paramsData) {
      if (paramsData[i] !== null && paramsData[i] !== 'null') {
        temp[i] = paramsData[i]
      }
    }
    return temp
  },
  getHttpData: function(data) {
    let paramsData = JSON.parse(JSON.stringify(data))
    var temp = ''
    for (var i in paramsData) {
      if (paramsData[i] != null && paramsData[i] != 'null') {
        if (typeof paramsData[i] == 'string') {
          if (paramsData[i].indexOf('&') != -1) {
            paramsData[i] = encodeURIComponent(paramsData[i])
          }
        }

        temp = temp + '&' + i + '=' + paramsData[i]
      }
    }
    temp = temp.substr(1, temp.length - 1)
    return temp
  },
  getPermisson: function() {
    return JSON.parse(localStorage.getItem('userPermission'))
  },
  setPermisson: function(permission) {
    localStorage.setItem('userPermission', JSON.stringify(permission))
  },
  clearPermisson: function() {
    localStorage.removeItem('userPermission')
  },
  toStringArray(data, dataList) {
    const dataArray = []
    const nameList = []
    for (const attr in data) {
      const id = parseInt(data[attr])
      if (!isNaN(id)) {
        dataArray.push(id)
      }
    }
    for (const attr in dataArray) {
      for (const att in dataList) {
        if (dataArray[attr] == dataList[att].id) {
          nameList.push(dataList[att].name)
        }
      }
    }
    return nameList
  },
  toNumArray(data) {
    const dataArray = []
    for (const attr in data) {
      const id = parseInt(data[attr])
      if (!isNaN(id)) {
        dataArray.push(id)
      }
    }
    return dataArray
  },
  setCookieExpires(time) {
    window.document.cookie =
      'expiresTime=' + escape(time) + ';expires=' + time + ';path=/'
  },
  getCookieExpires(self) {
    if (self.$cookie) {
      return self.$cookie.get('expiresTime')
    } else {
      return self.get('expiresTime')
    }
  },
  setToken(name, value, expiresTime) {
    var exp = new Date()
    exp.setTime(exp.getTime() + expiresTime * 1000)
    this.setCookieExpires(exp.toGMTString())
    window.document.cookie =
      name + '=' + escape(value) + ';expires=' + exp.toGMTString() + ';path=/'
  },
  getToken(self) {
    if (self.$cookie) {
      return self.$cookie.get('token')
    } else {
      return self.get('token')
    }
  },
  deleToken(name) {
    this.setToken(name, '', -1)
  },
  getIsid(self) {
    return self.$cookie.get('isid')
  },
  // 时间转换
  toDate(date) {
    if (date == null) {
      return null
    } else {
      var date2 = new Date(date)
      var toTwo = function(time) {
        if (time < 10) {
          time = '0' + time
          return time
        }
        return time
      }
      return (
        date2.getFullYear() +
        '-' +
        toTwo(date2.getMonth() + 1) +
        '-' +
        toTwo(date2.getDate()) +
        ' ' +
        toTwo(date2.getHours()) +
        ':' +
        toTwo(date2.getMinutes()) +
        ':' +
        toTwo(date2.getSeconds())
      )
    }
  },
  //errorMessage
  errorMessage(error, self) {
    if (error.response) {
      //匹配status
      if (error.response.status == 500) {
        this.error(self, '系统错误', '')
      }
      if (error.response.status == 404) {
        self.$router.push({ path: '/error' })
      }
      if (ErrorCode[error.response.status][error.response.data.code]) {
        this.error(
          self,
          ErrorCode[error.response.status][error.response.data.code],
          ''
        )
      } else {
        this.error(self, error.response.data.message, '')
      }
      if (error.response.status == 401) {
        self.$cookie.delete('token')
        self.$cookie.delete('channel')
        localStorage.setItem('markList', [])
        localStorage.setItem('editableTabs', '')
        self.$router.push({ name: 'Login', query: { layout: true } })
      }
    }
  },
}
