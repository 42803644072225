/*
 * @Description:
 * @Author: Ray
 * @Date: 2021-12-17 15:06:42
 * @LastEditTime: 2023-10-24 20:41:23
 * @LastEditors: Ray
 */
import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui'
import VueLazyload from 'vue-lazyload'
var VueCookie = require('vue-cookie')
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/app.css'
import '@/assets/css/theme.css'
import '@/assets/css/normalize.css'
import '@/assets/css/set1.css'
import '@/assets/plugins/iconfont/1.0.0/iconfont.css'
import '@/assets/plugins/iconfont/1.0.0/iconfont.js'

import Vuex from 'vuex'
import store from './vuex/store.js'
import global from './global/global.js'
import util from './util/util.js'
import dtime from 'time-formater'
import clipboard from 'clipboard'
import Qs from 'qs'
import accounting from 'accounting'
import VueUeditorWrap from 'vue-ueditor-wrap'
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Vuex)
Vue.use(VueCookie)
Vue.use(VueLazyload, {
  error: process.env.VUE_APP_URL + '/static/images/error.png',
  loading: process.env.VUE_APP_URL + '/static/images/loading.png',
})
Vue.component('vue-ueditor-wrap', VueUeditorWrap)
Vue.prototype.$qs = Qs
Vue.prototype.$accounting = accounting
Vue.prototype.$global = global
Vue.prototype.$util = util
Vue.prototype.$dtime = dtime
Vue.prototype.Clipboard = clipboard
Vue.prototype.$ElUpload = Vue.options.components.ElUpload.options.props
Vue.prototype.$table = {}
Vue.prototype.$table.moneyFormatter = function(row, column, cellValue, index) {
  if (cellValue != undefined) {
    return accounting.formatMoney(cellValue, '¥')
  } else {
    return ''
  }
}
Vue.prototype.$table.percentageFormatter = function(
  row,
  column,
  cellValue,
  index
) {
  if (cellValue != undefined) {
    return cellValue + '%'
  } else {
    return '-'
  }
}
// 路由拦截
router.beforeEach((to, from, next) => {
  // store.commit('clearRequest') // 取消请求
  // console.log(to.meta[0])
  if (to.meta[0].title) {
    document.title = to.meta[0].title
  }
  const isLogin = global.getToken(VueCookie)
  if (isLogin) {
    next()
  } else {
    if (to.meta[1].needLogin) {
      localStorage.setItem('markList', [])
      localStorage.setItem('editableTabs', '')
      global.deleToken('token')
      global.deleToken('channel')
      next({ name: 'Login' })
    } else {
      next()
    }
  }
})
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
// this.$cookies.config(60 * 30 * 1 * 1,'')
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>',
})
