/*
 * @Description:
 * @Author: Ray
 * @Date: 2024-02-02 13:29:06
 * @LastEditTime: 2024-02-06 17:11:52
 * @LastEditors: Ray
 */
const ErrorCode = {
  200: {
    10001: '时间戳错误',
    10004: '签名错误',
    10005: '请求频率过快',
    10010:'参数校验错误'
  },
  400: {
    //参数校验错误
    10004: '参数校验错误', // 400
  },
  401: {
    //token无效
    10002: '登录已失效，请重新登录',
    10003: '很抱歉，当前账户无角色权限',
    10012: '很抱歉，当前账户无角色权限',
    10011: '账号不存在',
  },
  403: {
    //权限不足
  },
  404: {
    //资源不存在
  },
  500: {
    //服务端错误
  },
}

export default ErrorCode
