<!--
 * @Description: 
 * @Author: Ray
 * @Date: 2022-01-05 16:41:50
 * @LastEditTime: 2023-10-25 16:57:55
 * @LastEditors: Ray
-->

<template>
  <div id="index" v-cloak>
    <!--左侧-->
    <el-container class="index-menu">
      <div class="left-tree" style="position:relative;"></div>
      <router-view />
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'Account',
  data() {
    return {}
  },
  watch: {},
  created() {},
  methods: {},
}
</script>
