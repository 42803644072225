/*
 * @Description:
 * @Author: Ray
 * @Date: 2023-10-30 16:00:35
 * @LastEditTime: 2024-02-02 11:11:19
 * @LastEditors: Ray
 */
import global from '@/global/global.js'

export const queryHcpListData = (self, data) => {
  const url = '/hcp'
  return global.httpGetWithToken(self, url, data)
}

export const delUnbindHcp = (self, id, data) => {
  const url = '/hcp/unbind/' + id
  return global.httpDeleteWithToken(self, url, data)
}

export const wechatlogout = (self, data) => {
  const url = '/hcp/wechatlogout/unbind/single'
  return global.httpPostWithToken(self, url, data)
}

export const wechatlogoutCsv = (self, file, config) => {
  const url = '/hcp/wechatlogout/unbind/csv'
  return global.httpFormPost(self, url, file, config)
}
