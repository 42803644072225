<template>
  <div
    id="app"
    class="index"
    v-loading="SSOloading"
    element-loading-text="登陆中"
    element-loading-background="rgba(255, 255, 255, 1)"
  >
    <!--大容器开始-->
    <div class="class-1" v-show="showSSO">
      <!--大容器开始-->
      <div v-show="!show" class="class-2" style="overflow: hidden">
        <div class="class-3">
          <img src="../../assets/images/1577694374686.png" class="class-4" />
        </div>
        <div class="class-5">
          <div class="class-6">
            <div class="class-7 removeLine">登录</div>
            <div class="login-sso">
              <div
                style="height: 320px; display: flex; justify-content: center"
              >
                <el-button
                  style="
                    width: 160px;
                    height: 160px;
                    border-radius: 50%;
                    font-size: 24px;
                    margin: auto;
                  "
                  @click="loginSSO"
                  type="primary"
                  :loading="SSOloading"
                  class="class-26"
                >
                  {{ SSOloading ? '登录中' : 'SSO登录' }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--大容器结束-->
  </div>
  <!--大容器结束-->
</template>
<script>
import { getSSOToken } from '@/api/layout.js'
export default {
  name: 'Login',
  data() {
    return {
      showSSO: false,
      SSOloading: false,
      show: false,
      expires_in: '',
    }
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {
    let that = this
    this.SSOloading = true
    localStorage.setItem('markList', [])
    localStorage.setItem('editableTabs', '')
    if (this.$global.getToken(this)) {
      location.href = '/Management'
    } else if (this.$util.getParameter('code')) {
      this.SSOloading = true
      this.checkSSO()
    } else if (this.$util.getParameter('layout')) {
      this.showSSO = true
      this.SSOloading = false
    } else if (!this.$global.getToken(this)) {
      this.loginSSO()
    }
  },
  mounted() {},
  destroyed() {},
  methods: {
    loginSSO() {
      this.SSOloading = true
      window.location.href =
        process.env.VUE_APP_SIGN_URL +
        '?client_id=' +
        process.env.VUE_APP_CLIENT_ID +
        '&response_type=' +
        process.env.VUE_APP_RESPONSE_TYPEL +
        '&redirect_uri=' +
        process.env.VUE_APP_REDIRECT_URL +
        '&state=sso'
    },
    checkSSO() {
      var that = this
      let data = {
        code: this.$util.getParameter('code'),
      }
      getSSOToken(that, data)
        .then((res) => {
          console.log(res.data)
          if (res.data) {
            console.log(res.data)
            that.$global.setToken(
              'token',
              res.data.access_token,
              res.data.expires_in
            )
            that.expires_in = res.data.expires_in

            localStorage.setItem('menuList', JSON.stringify(res.data.menus))
            localStorage.setItem(
              'email',
              JSON.stringify(res.data.email.replace('@organon.com', ''))
            )

            localStorage.setItem(
              'permissions',
              JSON.stringify(res.data.permissions)
            )
            window.location.replace(process.env.VUE_APP_REDIRECT_URL)
          }
        })
        .catch((err) => {
          console.log('error2', err)
          that.SSOloading = false
          that.showSSO = true
        })
    },
  },
}
</script>
<style>
.channel-box {
  width: 100%;
  height: 100%;
}
.channel-title {
  width: 100%;
  height: 30px;
  padding-left: 20px;
  /* background: rgb(179, 216, 255); */
}
.channel-content {
  display: flex;
  flex-flow: row wrap;
  flex-direction: inherit;
  justify-content: flex-start;
  /* padding-top: 20px; */
  padding: 20px 45px;
}
.channel-item {
  display: flex;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  width: 270px;
  height: 100px;
  margin: 20px 16px;
  cursor: pointer;
}
.channel-item-img {
  padding: 10px;
}
.channel-item-name {
  display: flex;
  align-items: center;
  padding: 0 30px;
}
.removeLine {
  caret-color: rgba(0, 0, 0, 0);
}
.login-from .el-form-item {
  margin-bottom: 0px !important;
}
.el-form-item__content {
  line-height: initial !important;
}
.custom-body {
}
.class-1 {
  color: #333333;
  background-image: url(../../assets/images/1577692394350.jpg);
  outline: none;
  outline-offset: -1px;
  background-size: cover;
  background-position: center;
  height: 100%;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: center;
  animation-duration: 1s;
  width: 100%;
  background-repeat: no-repeat;
}
.class-2 {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  color: #333333;
  outline: none;
  outline-offset: -1px;
  height: 540px;
  max-width: 100%;
  background-color: rgba(255, 255, 255, 1);
  flex-direction: row;
  display: flex;
  animation-duration: 1s;
  border-radius: 12px;
  width: 1000px;
  background-repeat: no-repeat;
}
.class-3 {
  color: #333333;
  outline: none;
  outline-offset: -1px;
  height: 100%;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  animation-duration: 1s;
  width: 460px;
  background-repeat: no-repeat;
}
.class-4 {
  /* height: 100%; */
  animation-duration: 1s;
  width: 125%;
}
.class-5 {
  color: #333333;
  outline: none;
  padding-bottom: 20px;
  outline-offset: -1px;
  flex: 1;
  padding-top: 20px;
  height: 100%;
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  animation-duration: 1s;
  width: 200px;
  background-repeat: no-repeat;
}
.class-6 {
  color: #333333;
  outline: none;
  outline-offset: -1px;
  max-width: 100%;
  flex-direction: column;
  display: flex;
  animation-duration: 1s;
  width: 330px;
  background-repeat: no-repeat;
  margin-top: 20px;
}
.class-7 {
  color: #333333;
  word-wrap: break-word;
  display: inline-block;
  animation-duration: 1s;
  font-size: 36px;
  line-height: 1.4;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
}
.input__label--hoshi::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: calc(100% - 10px);
  border-bottom: 1px solid #b9c1ca;
}
</style>
