/*
 * @Description:
 * @Author: Ray
 * @Date: 2021-12-27 11:08:37
 * @LastEditTime: 2024-01-22 15:07:16
 * @LastEditors: Ray
 */
import global from '@/global/global.js'


export const getSSOToken = (self, data) => {
  const url = '/auth/adminPortal/token'
  return global.httpGet(self, url, data)
}

export const queryMenuListData = (self, data) => {
  const url = '/api/auth/current/model'
  return global.httpGetWithToken(self, url, data)
}

export const queryManagerData = (self, data) => {
  const url = '/api/auth/current'
  return global.httpGetWithToken(self, url, data)
}
