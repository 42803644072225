/*
 * @Description:
 * @Author: Ray
 * @Date: 2021-12-17 15:06:44
 * @LastEditTime: 2024-02-02 10:02:14
 * @LastEditors: Ray
 */
import Vue from 'vue'

import Router from 'vue-router'
import Layout from '@/components/Layout/Layout'
import Login from '@/components/Login/Login'
import Error from '@/components/Error/Error'

// Account
import Account from '@/components/NewcoVInvite/Account/AccountIndex'
import AccountList from '@/components/NewcoVInvite/Account/AccountList'

/*   */
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Layout,
      redirect: '/Management',
      meta: [{ name: 'Management', pathName: '/' }, { needLogin: true }],
    },
    {
      path: '/Login',
      name: 'Login',
      component: Login,
      meta: [{ name: 'Login', pathName: '/' }, { needLogin: false }],
    },
    {
      path: '/Management',
      name: 'Management',
      component: Layout,
      meta: [{ name: 'Management', pathName: 'Management' }, { needLogin: true }],
      children: [
        // NewcoVInvite
        {
          path: 'Account',
          component: Account,
          name: 'Account',
          meta: [{ name: 'Account', pathName: 'Account' }, { needLogin: true }],
          children: [
            {
              path: 'AccountList',
              component: AccountList,
              name: 'AccountList',
              meta: [
                {
                  name: 'AccountList',
                  pathName: 'AccountList',
                  parentName: 'Account',
                },
                { needLogin: true },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '*',
      name: 'Error',
      component: Error,
      meta: [{ name: '404', pathName: '/Error' }, { needLogin: false }],
    },
  ],
})
