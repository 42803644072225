<!--
 * @Description: 
 * @Author: Ray
 * @Date: 2022-01-05 16:43:17
 * @LastEditTime: 2024-03-05 10:06:55
 * @LastEditors: Ray
-->

<template>
  <div id="main" class="ms-index" v-cloak>
    <el-header class="ms-header" height="50px"> </el-header>
    <div class="ms-search article-search">
      <el-row>
        <el-form :model="form" ref="searchForm" label-width="120px" size="mini">
          <el-row>
            <el-col :span="6">
              <el-form-item label="UnionID" prop="unionId">
                <el-input
                  v-model="form.unionId"
                  :disabled="false"
                  :style="{ width: '100%' }"
                  :clearable="true"
                  placeholder="请输入UnionID"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="OpenID" prop="openId">
                <el-input
                  v-model="form.openId"
                  :disabled="false"
                  :style="{ width: '100%' }"
                  :clearable="true"
                  placeholder="请输入OpenID"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="MDM code" prop="mdmCode">
                <el-input
                  v-model="form.mdmCode"
                  :disabled="false"
                  :style="{ width: '100%' }"
                  :clearable="true"
                  placeholder="请输入MDM code"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="姓名" prop="name">
                <el-input
                  v-model="form.name"
                  :disabled="false"
                  :style="{ width: '100%' }"
                  :clearable="true"
                  placeholder="请输入姓名"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-collapse-transition>
            <div v-show="showSearch">
              <el-row>
                <el-col :span="6">
                  <el-form-item label="工作地点" prop="workplaceName">
                    <el-input
                      v-model="form.workplaceName"
                      :disabled="false"
                      :style="{ width: '100%' }"
                      :clearable="true"
                      placeholder="请输入工作地点"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="注册来源" prop="clientName">
                    <el-select
                      v-model="form.clientName"
                      clearable
                      :style="{ width: '100%' }"
                      placeholder="请选择"
                    >
                      <el-option label="全部" value="null"></el-option>
                      <el-option label="OLXY" value="OLXY"></el-option>
                      <el-option label="wechat" value="wechat"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="实名状态" prop="mobile">
                    <el-select
                      v-model="form.mobile"
                      clearable
                      :style="{ width: '100%' }"
                      placeholder="请选择"
                    >
                      <el-option label="全部" value="null"></el-option>
                      <el-option label="已实名" value="1"></el-option>
                      <el-option label="未实名" value="0"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="关注状态" prop="isFollow">
                    <el-select
                      v-model="form.isFollow"
                      clearable
                      :style="{ width: '100%' }"
                      placeholder="请选择"
                    >
                      <el-option label="全部" value="null"></el-option>
                      <el-option label="已关注" value="1"></el-option>
                      <el-option label="未关注" value="0"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="绑定状态" prop="validateState">
                    <el-select
                      v-model="form.validateState"
                      clearable
                      :style="{ width: '100%' }"
                      placeholder="请选择"
                    >
                      <el-option label="全部" value="null"></el-option>
                      <el-option label="验证中" value="pending"></el-option>
                      <el-option label="已绑定" value="validated"></el-option>
                      <el-option label="被拒绝" value="rejected"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="MDM状态" prop="bindState">
                    <el-select
                      v-model="form.bindState"
                      clearable
                      :style="{ width: '100%' }"
                      placeholder="请选择"
                    >
                      <el-option label="全部" value="null"></el-option>
                      <el-option label="已绑定" value="1"></el-option>
                      <el-option label="未绑定" value="0"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item label="绑定时间" prop="bindDate">
                    <el-date-picker
                      style="width:100%"
                      v-model="bindDate"
                      @change="bindDateChange"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="微信昵称" prop="nickName">
                    <el-input
                      v-model="form.nickName"
                      :disabled="false"
                      :style="{ width: '100%' }"
                      :clearable="true"
                      placeholder="请输入微信昵称"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="consent状态" prop="consentState">
                    <el-select
                      v-model="form.consentState"
                      clearable
                      :style="{ width: '100%' }"
                      placeholder="请选择"
                    >
                      <el-option label="全部" value="null"></el-option>
                      <el-option label="已勾选" value="1"></el-option>
                      <el-option label="未勾选" value="0"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="实名时间" prop="bindDate">
                    <el-date-picker
                      style="width:100%"
                      v-model="mobileBindDate"
                      @change="mobileBindDateChange"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="注册方式" prop="bindSource">
                    <el-select
                      v-model="form.bindSource"
                      clearable
                      :style="{ width: '100%' }"
                      placeholder="请选择"
                    >
                      <el-option label="全部" value="null"></el-option>
                      <el-option label="register" value="register"></el-option>
                      <el-option label="scan" value="scan"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="角色" prop="role">
                    <el-select
                      v-model="form.role"
                      clearable
                      :style="{ width: '100%' }"
                      placeholder="请选择"
                    >
                      <el-option label="全部" value="null"></el-option>
                      <el-option label="零售药店药师" value="hcp"></el-option>
                      <el-option
                        label="零售药店店员"
                        value="salesclerk"
                      ></el-option>
                      <el-option
                        label="零售药店管理人员"
                        value="manager"
                      ></el-option>
                      <el-option
                        label="零售药店管理人员(药师)"
                        value="hcp_manager"
                      ></el-option>
                      <el-option label="大众" value="public"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="是否转粉" prop="transferState">
                    <el-select
                      v-model="form.transferState"
                      clearable
                      :style="{ width: '100%' }"
                      placeholder="请选择"
                    >
                      <el-option label="全部" value="null"></el-option>
                      <el-option label="是" value="1"></el-option>
                      <el-option label="否" value="0"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="邀请人" prop="inviter">
                    <el-input
                      v-model="form.inviter"
                      :disabled="false"
                      :style="{ width: '100%' }"
                      :clearable="true"
                      placeholder="请输入邀请人"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-collapse-transition>

          <el-row>
            <el-col :span="16" style="text-align:left;padding-left:40px">
              <el-button
                type="primary"
                icon="el-icon-search"
                size="mini"
                v-if="$global.comparePermisson('retailAdminPortal:list:find')"
                @click="
                  page.offset = 0
                  list()
                "
                >查询</el-button
              >
              <el-button @click="rest" icon="el-icon-refresh" size="mini"
                >重置</el-button
              >
              <el-button
                type="primary"
                icon="el-icon-upload"
                class="sub hcp_top_form_sub"
                size="mini"
                v-if="
                  $global.comparePermisson(
                    'retailAdminPortal:list:wechatlogoutCsv'
                  )
                "
                @click="centerDialogVisible = true"
                >批量注销</el-button
              >
              <el-dialog
                title="批量注销微信"
                :visible.sync="centerDialogVisible"
                v-loading="dialogLoading"
                append-to-body
                width="600px"
                center
              >
                <p style="color:red;line-height: 30px;text-align: center;">
                  *此操作不可逆，请确认文件内账号名单后进行此操作
                </p>

                <el-upload
                  class="upload-demo"
                  drag
                  ref="upload"
                  action="actionUrl"
                  :on-error="handleError"
                  :http-request="uploadFile"
                  :on-change="onChange"
                  :file-list="fileList"
                  :on-remove="removeFile"
                  :auto-upload="false"
                  :limit="1"
                  accept="text/csv"
                  multiple
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    将文件拖到此处，或<em>点击上传</em>
                  </div>
                  <div class="el-upload__tip" slot="tip">
                    只能上传csv文件
                  </div>
                </el-upload>
                <el-form
                  :model="logoutForm"
                  ref="logoutForm"
                  label-width="70px"
                  style="margin-top: 20px;"
                >
                  <el-form-item
                    label="备注"
                    prop="comment"
                    :rules="[{ required: true, message: '备注不能为空' }]"
                  >
                    <el-input v-model="logoutForm.comment"></el-input>
                  </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="cancleLogout">取 消</el-button>
                  <el-button type="primary" @click="agreeUpload"
                    >确 定</el-button
                  >
                </span>
              </el-dialog>
            </el-col>
            <el-col :span="8" style="text-align:right;padding-right:10px">
              <span v-if="showSearch" class="right_icon" @click="shSearch">
                <span>收起</span>
                <i
                  class="el-icon-d-arrow-right"
                  style="display:inline-block;transform:rotate(-90deg);"
                ></i>
              </span>
              <span v-if="!showSearch" class="right_icon" @click="shSearch">
                <span>更多搜索</span>
                <i
                  class="el-icon-d-arrow-right"
                  style="display:inline-block;transform:rotate(90deg)"
                ></i>
              </span>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </div>
    <el-main class="ms-container">
      <el-table
        v-loading="loading"
        ref="multipleTable"
        border
        :header-cell-style="tableHeaderColor"
        :data="dataList"
        tooltip-effect="dark"
      >
        <template slot="empty">
          {{ emptyText }}
        </template>
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form
              label-position="center"
              label-width="150px"
              inline
              class="demo-table-expand"
            >
              <el-form-item class="table_form_item" label="微信昵称">
                <span>
                  {{ props.row.nickName }}
                </span>
              </el-form-item>
              <el-form-item class="table_form_item"  label="关注状态">
                <span v-if="props.row.isFollow == '1'">
                  已关注
                </span>
                <span v-else>未关注</span>
              </el-form-item>
              <el-form-item class="table_form_item"  label="MDM状态">
                <span v-if="props.row.validateState == 'validated'">
                  已绑定
                </span>
                <span v-else>
                  未绑定
                </span>
              </el-form-item>
              <el-form-item  class="table_form_item"  label="consent状态">
                <span v-if="props.row.consentState == '1'">
                  已勾选
                </span>
                <span v-else>未勾选</span>
              </el-form-item>
              <el-form-item  class="table_form_item"  label="注册方式">
                <span>{{ props.row.bindSource }}</span>
              </el-form-item>
              <!-- <el-form-item label="邀请人">
                <span>{{ props.row.inviter }}</span>
              </el-form-item> -->
              <el-form-item  class="table_form_item"  label="是否转粉">
                <span v-if="props.row.transferState == '1'">
                  转粉
                </span>
                <span v-else>非转粉</span>
              </el-form-item>
              <!-- <el-form-item label="mobile">
                <span>{{ props.row.mobile }}</span>
              </el-form-item> -->
              <el-form-item class="table_form_item"  label="注册来源">
                <span>{{ props.row.clientName }}</span>
              </el-form-item>
              <el-form-item class="table_form_item"  label="实名时间">
                <span>{{ dateFormat('', '', props.row.mobileBindTime) }}</span>
              </el-form-item>
              <el-form-item class="table_form_item"  label="更新时间">
                <span>{{ dateFormat('', '', props.row.updateTime) }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          label="姓名"
          align="center"
          prop="name"
          width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.name">
              {{ scope.row.name }}
            </span>
            <span v-else>
              --
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="unionId"
          align="center"
          prop="unionId"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="openId"
          align="center"
          prop="openId"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="角色"
          align="center"
          prop="role"
          width="230"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.role == 'hcp'">
              零售药店药师
            </span>
            <span v-if="scope.row.role == 'salesclerk'">
              零售药店店员
            </span>
            <span v-if="scope.row.role == 'manager'">
              零售药店管理人员
            </span>
            <span v-if="scope.row.role == 'hcp_manager'">
              零售药店管理人员(药师)
            </span>
            <span v-if="scope.row.role == 'public'">
              大众
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="绑定状态"
          align="center"
          prop="validateState"
          width="130"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.validateState == 'pending'">
              验证中
            </span>
            <span v-else-if="scope.row.validateState == 'validated'">
              已绑定
            </span>
            <span v-else-if="scope.row.validateState == 'rejected'">
              被拒绝
            </span>
            <span v-else>
              --
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="MDM状态"
          align="center"
          prop="validateState"
          width="130"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.validateState == 'validated'">
              已绑定
            </span>
            <span v-else>
              未绑定
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="MDM Code"
          align="center"
          prop="mdmCode"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.mdmCode">
              {{ scope.row.mdmCode }}
            </span>
            <span v-else>
              --
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="工作地点"
          align="center"
          prop="workplaceName"
          width="230"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.workplaceName">
              {{ scope.row.workplaceName }}
            </span>
            <span v-else>
              --
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="实名状态"
          align="center"
          prop="mobile"
          width="130"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.mobile">
              已实名
            </span>
            <span v-else>
              未实名
            </span>
          </template>
        </el-table-column>

        <el-table-column
          label="注册方式"
          align="center"
          prop="bindSource"
          width="100"
        >
        </el-table-column>
        <el-table-column
          label="注册来源"
          align="center"
          prop="clientName"
          width="130"
        >
        </el-table-column>
        <el-table-column
          label="创建时间"
          align="center"
          prop="createTime"
          :formatter="dateFormat"
          width="180"
        >
        </el-table-column>
        <el-table-column
          label="绑定时间"
          align="center"
          prop="bindTime"
          :formatter="dateFormat"
          width="180"
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="210" align="center">
          <template slot-scope="scope">
            <el-button
              @click="deleteHcp($event, scope.row)"
              type="danger"
              v-if="$global.comparePermisson('retailAdminPortal:list:unbind')"
              size="mini"
              >解绑</el-button
            >
            <el-button
              @click="wechatLogout($event, scope.row)"
              v-if="
                $global.comparePermisson(
                  'retailAdminPortal:list:wechatlogoutSingle'
                )
              "
              type="danger"
              size="mini"
              >注销账号</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="offset"
        :page-size="page.limit"
        :total="page.total"
        class="ms-pagination"
        @current-change="currentChange"
        @size-change="sizeChange"
      >
      </el-pagination>
    </el-main>
  </div>
</template>

<script>
import {
  queryHcpListData,
  delUnbindHcp,
  wechatlogout,
  wechatlogoutCsv,
} from '@/api/RetailVinvitation/account.js'
export default {
  name: 'AccountIndex',
  data() {
    return {
      dataList: [],
      showSearch: false,
      //总记录数量
      offset: 0,
      page: {
        limit: 10,
        offset: 0,
        total: 0,
      },
      //加载状态
      loading: true,
      centerDialogVisible: false,
      dialogLoading: false,
      //提示文字
      emptyText: '',
      //搜索表单
      bindDate: '',
      mobileBindDate: '',
      errorMessage: '',
      commentMessage: '',
      logoutForm: {
        comment: '',
      },
      fileList: [],
      form: {
        bindSource: '',
        clientName: '',
        consentState: '',
        isFollow: '',
        nickName: '',
        openId: '',
        unionId: '',
        mdmCode: '',
        name: '',
        workplaceName: '',
        role: '',
        mobile: '',
        transferState: '',
        validateState: '',
        bindDateBegin: '',
        bindDateEnd: '',
        mobileTimeFrom: '',
        mobileTimeTo: '',
      },
    }
  },
  watch: {},
  created() {
    this.list()
    if (history.hasOwnProperty('state')) {
      this.form = history.state.form
      this.page.offset = history.state.page.offset
      this.page.limit = history.state.page.limit
    }
  },
  methods: {
    cancleLogout() {
      this.fileList = []
      this.logoutForm.comment = ''
      this.centerDialogVisible = false
      this.$refs.logoutForm.clearValidate()
      this.$refs.upload.clearFiles()
    },
    agreeUpload() {
      var _this = this
      this.$refs.logoutForm.validate(function(valid) {
        if (valid) {
          if (_this.fileList.length > 0) {
            _this.uploadFile()
          } else {
            _this.$notify({
              title: '错误提示',
              message: '请上传csv文件',
              type: 'error',
              offset: 38,
              duration: 0,
            })
          }
        } else {
          return false
        }
      })
    },
    uploadFile() {
      const _this = this
      _this.fileList[0].status = 'uploading'
      _this.fileList[0].percentage = 0
      var file = new FormData()
      file.append('file', _this.fileList[0].raw)
      file.append('type', 'file')
      file.append('comment', _this.logoutForm.comment)
      const config = {
        headers: {
          Authorization: 'Bearer ' + _this.$cookie.get('token'),
          'Content-Type': 'multipart/form-data', //含有附件的表单提交的Content-Type
        },
        timeout: 0,
        onUploadProgress: function(progressEvent) {
          // 处理上传进度事件，在这里改变percentage的值吧
          const percent = (
            (progressEvent.loaded / progressEvent.total) *
            100
          ).toFixed(0) //计算出上传的进度，去整数位
          _this.fileList[0].percentage = percent
        },
      }
      _this.dialogLoading = true
      wechatlogoutCsv(_this, file, config)
        .then((res) => {
          _this.centerDialogVisible = false
          _this.dialogLoading = false
          _this.$notify({
            title: '成功',
            message: '批量注销成功',
            type: 'success',
            offset: 38,
          })
          _this.cancleLogout()
          _this.logoutForm.comment = ''
          _this.list()
        })
        .catch((err) => {
          _this.fileList[0].status = 'failed'
          _this.dialogLoading = false
          _this.$refs.upload.clearFiles()
          _this.$notify({
            title: '错误',
            message: err,
            type: 'error',
            offset: 38,
            duration: 0,
          })
        })
    },
    onChange(file, fileList) {
      this.fileList = fileList
      this.beforeUpload(fileList[0].raw)
    },
    removeFile() {
      this.fileList = []
      this.$refs.upload.clearFiles()
    },
    handleError(err, file, fileList) {
      const errorData = JSON.parse(err.toString().replace('Error: ', ''))
      if (file.status === 'fail') {
        if (errorData.msg) {
          this.$notify({
            title: '错误',
            message: errorData.msg,
            type: 'error',
            offset: 38,
            duration: 0,
          })
        } else {
          this.$notify({
            title: '错误',
            message: '服务器繁忙，请联系IT管理员',
            type: 'error',
            offset: 38,
            duration: 0,
          })
        }
      }
    },
    beforeUpload(file) {
      const isCsv = file.type === 'text/csv'
      if (!isCsv) {
        this.$notify({
          title: '错误提示',
          message: '只支持text/csv格式的文件',
          type: 'error',
          offset: 38,
          duration: 0,
        })
        this.fileList = []
        this.$refs.upload.clearFiles()
        return false
      }
    },
    //解绑确认弹窗及接口
    deleteHcp($event, row) {
      if ($event.detail == 0) {
        return
      }
      const _this = this
      const h = this.$createElement
      var dom = document.getElementById('foo')
      var errorDom = document.getElementById('error_worning')
      if (errorDom) {
        _this.errorMessage = ''
        errorDom.innerHTML = ''
      }
      if (dom) {
        dom.value = ''
      }
      this.$msgbox({
        title: '提示',
        message: h('div', { style: '' }, [
          h(
            'p',
            { style: 'margin-bottom:10px;font-size:13px;' },
            '解除绑定后将清除该用户在本系统中的所有信息,确定解除绑定?'
          ),
          h(
            'div',
            {
              class: {
                textarea: true,
                bar: false,
              },
            },
            [
              h('span', { style: 'float:left;color: red;' }, '*'),
              h('span', { style: 'float:left;font-size:13px;' }, '解绑原因:'),
              h(
                'textarea',
                {
                  class: {
                    textarea: true,
                    bar: false,
                  },
                  attrs: {
                    id: 'foo',
                    placeholder: '请输入解绑原因',
                    type: 'text',
                    resize: 'none',
                    rows: '4',
                  },
                  style: {
                    float: 'right',
                    'font-size': '13px',
                    resize: 'none',
                    width: '80%',
                    border: '1px solid #DCDFE6',
                    maxlength: 255,
                  },
                  on: {
                    input: (event) => {
                      var dom = document.getElementById('foo')
                      var errorDom = document.getElementById('error_worning')
                      _this.errorMessage = ''
                      errorDom.innerHTML = ''
                      _this.commentMessage = dom.value.trim()
                    },
                    blur: () => {
                      var errorDom = document.getElementById('error_worning')
                      if (_this.commentMessage.length == 0) {
                        _this.errorMessage = '解绑原因不能为空'
                        errorDom.innerHTML = _this.errorMessage
                      } else {
                        _this.errorMessage = ''
                        errorDom.innerHTML = ''
                      }
                    },
                  },
                },
                ''
              ),
              h(
                'span',
                {
                  attrs: {
                    id: 'error_worning',
                  },
                  style:
                    'float:right;width:80%;font-size:13px;color:red;text-align:left;text-indent:5px;height:18px;',
                },
                ''
              ),
            ]
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: '',
        autofocus: true,
        type: 'warning',
        center: true,
        beforeClose(action, instance, done) {
          if (action == 'confirm') {
            this.$nextTick(() => {
              if (window.event.detail != 0) {
                var errorDom = document.getElementById('error_worning')
                if (_this.commentMessage.length == 0) {
                  _this.errorMessage = '解绑原因不能为空'
                  errorDom.innerHTML = _this.errorMessage
                } else {
                  _this.errorMessage = ''
                  errorDom.innerHTML = ''
                }
                if (_this.commentMessage.length == 0) {
                  return
                }
                if (_this.errorMessage.length != 0) {
                  return
                }
                done()
                _this.loading = true
                const commentData = {
                  comment: _this.commentMessage,
                }
                delUnbindHcp(_this, row.unionId, commentData)
                  .then((res) => {
                    _this.$notify({
                      title: '成功',
                      message: '解绑成功',
                      type: 'success',
                      offset: 38,
                    })
                    _this.loading = false
                    var errorDom = document.getElementById('error_worning')
                    _this.errorMessage = ''
                    errorDom.innerHTML = ''
                    _this.commentMessage = ''
                    _this.list()
                  })
                  .catch(() => {
                    _this.loading = false
                    var errorDom = document.getElementById('error_worning')
                    _this.errorMessage = ''
                    errorDom.innerHTML = ''
                    _this.commentMessage = ''
                  })
              }
            })
          } else {
            const errorDom = document.getElementById('error_worning')
            _this.errorMessage = ''
            errorDom.innerHTML = ''
            _this.commentMessage = ''
            done()
          }
        },
      })
        .then(() => {})
        .catch(() => {})
    },
    //微信注销
    wechatLogout($event, row) {
      if ($event.detail == 0) {
        return
      }
      const _this = this
      const h = this.$createElement
      var dom = document.getElementById('foo')
      var errorDom = document.getElementById('error_worning')
      if (errorDom) {
        _this.errorMessage = ''
        errorDom.innerHTML = ''
      }
      if (dom) {
        dom.value = ''
      }
      this.$msgbox({
        title: '提示',
        message: h('div', { style: '' }, [
          h(
            'p',
            { style: 'margin-bottom:10px;font-size:13px;' },
            '注销账户后将清除该用户在本系统中的所有信息及日志,并会将注销信息同步推送给三方系统，确定注销账户?'
          ),
          h(
            'div',
            {
              class: {
                textarea: true,
                bar: false,
              },
            },
            [
              h('span', { style: 'float:left;color: red;' }, '*'),
              h('span', { style: 'float:left;font-size:13px;' }, '注销原因:'),
              h(
                'textarea',
                {
                  class: {
                    textarea: true,
                    bar: false,
                  },
                  attrs: {
                    id: 'foo',
                    placeholder: '请输入注销原因',
                    type: 'text',
                    resize: 'none',
                    rows: '4',
                  },
                  style: {
                    float: 'right',
                    'font-size': '13px',
                    resize: 'none',
                    width: '80%',
                    border: '1px solid #DCDFE6',
                    maxlength: 255,
                  },
                  on: {
                    input: (event) => {
                      var dom = document.getElementById('foo')
                      var errorDom = document.getElementById('error_worning')
                      _this.errorMessage = ''
                      errorDom.innerHTML = ''
                      _this.commentMessage = dom.value.trim()
                    },
                    blur: () => {
                      var errorDom = document.getElementById('error_worning')
                      if (_this.commentMessage.length == 0) {
                        _this.errorMessage = '注销原因不能为空'
                        errorDom.innerHTML = _this.errorMessage
                      } else {
                        _this.errorMessage = ''
                        errorDom.innerHTML = ''
                      }
                    },
                  },
                },
                ''
              ),
              h(
                'span',
                {
                  attrs: {
                    id: 'error_worning',
                  },
                  style:
                    'float:right;width:80%;font-size:13px;color:red;text-align:left;text-indent:5px;height:18px;',
                },
                ''
              ),
            ]
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: '',
        autofocus: true,
        type: 'warning',
        center: true,
        beforeClose(action, instance, done) {
          if (action == 'confirm') {
            this.$nextTick(() => {
              if (window.event.detail != 0) {
                var errorDom = document.getElementById('error_worning')
                if (_this.commentMessage.length == 0) {
                  _this.errorMessage = '注销原因不能为空'
                  errorDom.innerHTML = _this.errorMessage
                } else {
                  _this.errorMessage = ''
                  errorDom.innerHTML = ''
                }
                if (_this.commentMessage.length == 0) {
                  return
                }
                if (_this.errorMessage.length != 0) {
                  return
                }
                done()
                _this.loading = true
                const commentData = {
                  data: {
                    unionid: row.unionId,
                    comment: _this.commentMessage,
                  },
                }
                wechatlogout(_this, commentData)
                  .then((res) => {
                    _this.$notify({
                      title: '成功',
                      message: '注销成功',
                      type: 'success',
                      offset: 38,
                    })
                    _this.loading = false
                    var errorDom = document.getElementById('error_worning')
                    _this.errorMessage = ''
                    errorDom.innerHTML = ''
                    _this.commentMessage = ''
                    _this.list()
                  })
                  .catch(() => {
                    _this.loading = false
                    var errorDom = document.getElementById('error_worning')
                    _this.errorMessage = ''
                    errorDom.innerHTML = ''
                    _this.commentMessage = ''
                  })
              }
            })
          } else {
            const errorDom = document.getElementById('error_worning')
            _this.errorMessage = ''
            errorDom.innerHTML = ''
            _this.commentMessage = ''
            done()
          }
        },
      })
        .then(() => {})
        .catch(() => {})
    },
    bindDateChange(value) {
      if (value) {
        this.form.bindTimeFrom = value[0]
        this.form.bindTimeTo = value[1]
      } else {
        this.form.bindTimeFrom = ''
        this.form.bindTimeTo = ''
      }
    },
    mobileBindDateChange(value) {
      if (value) {
        this.form.mobileTimeFrom = value[0]
        this.form.mobileTimeTo = value[1]
      } else {
        this.form.mobileTimeFrom = ''
        this.form.mobileTimeTo = ''
      }
    },
    shSearch() {
      this.showSearch = !this.showSearch
    },
    createTimeChange(value) {
      if (value) {
        this.form.begin = value[0]
        this.form.end = value[1]
      } else {
        this.form.begin = ''
        this.form.end = ''
      }
    },
    list() {
      var that = this
      that.loading = true
      var page = {
        offset: that.page.offset,
        limit: that.page.limit,
      }
      var form = JSON.parse(JSON.stringify(that.form))
      if (form.begin) {
        form.begin = that.$util.timestampToTime(form.begin)
      }
      if (form.end) {
        form.end = that.$util.timestampToTime(form.end)
      }
      for (var key in form) {
        if (!form[key] || form[key].length == 0) {
          delete form[key]
        }
      }
      history.replaceState(
        {
          form: form,
          page: page,
        },
        ''
      )
      let data = Object.assign(form, page)
      queryHcpListData(that, data)
        .then(function(res) {
          that.loading = false
          if (res.page.total <= 0) {
            that.emptyText = '暂无数据'
            that.dataList = []
            that.page.total = 0
          } else {
            that.emptyText = ''
            that.page.total = res.page.total
            that.dataList = res.data
          }
        })
        .catch((err) => {
          that.loading = false
        })
        .finally(function() {
          that.loading = false
        })
    },
    dateFormat(row, column, cellValue, index) {
      if (cellValue) {
        // 'yyyy-MM-dd hh:mm:ss'
        return this.$util.date.fmt(cellValue, 'yyyy-MM-dd hh:mm:ss')
      } else {
        return ''
      }
    },
    //pageSize改变时会触发
    sizeChange(limit) {
      this.loading = true
      this.page.limit = limit
      this.page.offset = 0
      this.list()
    },
    //offset改变时会触发
    currentChange(offset) {
      this.loading = true
      this.page.offset = (offset - 1) * this.page.limit
      this.list()
    },
    //重置表单
    rest() {
      this.$refs.searchForm.resetFields()
      this.bindDate = []
      this.mobileBindDate = []
      this.list()
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'font-size:16px;font-weight: 700;'
      }
    },
  },
  mounted() {},
}
</script>
<style scope>
.table_form_item{
  display: flex!important;
  align-items: center;
}
.table_form_item .el-form-item__label{
  text-align: center!important;
}
.upload-demo {
  text-align: center;
}
.dialog-footer .el-button {
  margin: 0px;
  width: 74px;
  height: 40px;
}
.dialog-footer .el-button:nth-child(1) {
  margin-right: 40px;
}
.dialog-footer .el-button:nth-child(2) {
  display: inline-block;
}
.el-table--scrollable-x .el-table__body-wrapper {
  height: auto !important;
}
.el-table {
  flex: none !important;
}
.article-search {
  padding-bottom: 30px !important;
}
.upload-demo {
  text-align: center;
}
.right_icon {
  color: #409eff;
  cursor: pointer;
  position: relative;
  top: 2px;
  right: 0;
  animation: jump 0.5s ease-in-out infinite alternate;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
/* .right_icon:after{

} */
@keyframes jump {
  from {
    top: 2px;
  }
  to {
    top: -2px;
  }
}
</style>
